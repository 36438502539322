import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum,
} from "./apiCallerHelper.core";

export async function postConsumerApplicationToInstance({
  Id,
  selectedClient,
  selectedUse,
  selectedFunding,
  selectedRole,
  Name,
  LastName,
  Email,
  phoneNumber,
  devices,
  consumption,
  useType,
}) {
  let route = "/api/applications/toinstance/";
  let body = {
    Id,
    selectedClient,
    selectedUse,
    selectedFunding,
    selectedRole,
    Name,
    LastName,
    Email,
    phoneNumber,
    devices,
    consumption,
    useType,
  };
  return postAsync(route, body);
}
