import GlobalParametersHelper from "./GlobalParametersHelper";
import { DynamicStepActionTypes } from "../common/enums/dynamicStepActionTypes.enum";
export const WorkflowInstancesHelper = {
  Action: {
    IsAllRequiredFieldsInStepWithValue: (
      steps,
      stepStatus,
      files,
      isCustomFlorina
    ) => {
      function isEmptyStringOrNull(value) {
        let isEmptyStringOrNull = !value || !value.toString().trim();
        return isEmptyStringOrNull;
      }

      /**
       * Searches for all required and that they dont have a value except from action type(button or files)
       * @param {*} actions
       */
      function getRequiredFirstActionWithoutDataExceptFromFilesAndButtons(
        actions
      ) {
        let result = currentStepStatus.actions.find(
          (x) =>
            x.action.Type != DynamicStepActionTypes.button &&
            x.action.Type != DynamicStepActionTypes.file &&
            x.action.Type !=
              DynamicStepActionTypes.dynamic_grid_technical_description &&
            x.action.Required == true &&
            isEmptyStringOrNull(x.data.Data)
        );
        return result;
      }

      /**
       * Checks whether the action contains a file
       * @param {*} action
       * @param {*} files
       */
      function getIsActionWithFiles(actionId, files) {
        let result = files.find((x) => x.ActionId == actionId);
        return result;
      }

      /**
       * Gets the required actions of type files without files
       * @param {*} actions
       */
      function getRequiredFirstActionTypeFileWithoutData(actions, files) {
        files = files || [];
        return (
          actions.find(({ action }) => {
            if (
              action.Type === DynamicStepActionTypes.file &&
              action.Required
            ) {
              function _isSkipElementCheck(_id, _isCustomFlorina) {
                if (isCustomFlorina == false) {
                  if (
                    Florina.getFlorinaSpecificActionTypeFilesValues().indexOf(
                      action.Id
                    ) > -1
                  ) {
                    return true;
                  }
                }
                return false;
              }

              if (_isSkipElementCheck(action.Id, isCustomFlorina) == true) {
                return false;
              }

              //TODO ADD SPECIAL CASES HERE: checks if any of the items within the list has value, and if so
              //then we skip the check for the related action (if a has value then b is not required etc)
              function hasCustomRelatedActionsRequiredValid(actionId) {
                let isValid = false;
                let relatedRequiredIfOneIsNotWithDataFileActions = [
                  {
                    a: Florina.FlorinaSpecificActionTypeFilesEnum.TAX_E1,
                    b: Florina.FlorinaSpecificActionTypeFilesEnum
                      .HOME_USE_DECLERATION,
                  },
                ];
                if (
                  relatedRequiredIfOneIsNotWithDataFileActions
                    .map((obj) => Object.values(obj))
                    .flat()
                    .indexOf(actionId) !== -1
                ) {
                  function _checkIfAnyActionHasValue(actionIdA, actionIdB) {
                    let hasFilesActionA = getIsActionWithFiles(
                      actionIdA,
                      files
                    );
                    let hasFilesActionB = getIsActionWithFiles(
                      actionIdB,
                      files
                    );
                    let hasFiles =
                      hasFilesActionA != null || hasFilesActionB != null;
                    return hasFiles;
                  }

                  relatedRequiredIfOneIsNotWithDataFileActions.forEach(
                    (relatedAction) => {
                      const [anActionId, otherActionId] =
                        relatedAction.a === action.Id
                          ? [relatedAction.a, relatedAction.b]
                          : [relatedAction.b, relatedAction.a];

                      // Skip the check if any of the related actions have a value
                      if (
                        _checkIfAnyActionHasValue(
                          anActionId,
                          otherActionId,
                          files
                        )
                      ) {
                        isValid = true;
                      }
                    }
                  );
                }
                return isValid;
              }
              if (hasCustomRelatedActionsRequiredValid(action.Id)) {
                return false;
              }

              return !getIsActionWithFiles(action.Id, files);
            }
            return false;
          }) || null
        );
      }

      let currentStepStatus = steps.find(
        (step) => step.step.Status == stepStatus
      );
      if (currentStepStatus) {
        const { actions } = currentStepStatus;
        const result =
          getRequiredFirstActionWithoutDataExceptFromFilesAndButtons(actions) ||
          getRequiredFirstActionTypeFileWithoutData(actions, files);
        if (result) {
          const {
            action: {
              Name: name,
              Description: description,
              Id: id,
              Type: type,
            },
          } = result;
          return { name, description, id, type };
        }
      }
      return null;
    },
    /**
     * adds an asterisc on required action name, removes asterisc if already found in name first
     * @param {*} action
     * @returns
     */
    getNameWithAsteriscIfRequired: (action) => {
      const trimmedStr = action.Name.replace(/\*/g, "").trim(); //remove asterisc
      let result = trimmedStr + (action.Required ? "*" : ""); //add asterisc if required
      return result;
    },
  },
  IsWorfklowInstanceStepActionEnabledByCustomFlag:
    IsWorfklowInstanceStepActionEnabledByCustomFlag,
  IsWorfklowInstanceStepEnabledByCustomFlag:
    IsWorfklowInstanceStepEnabledByCustomFlag,
  IsWorfklowInstanceStepActionForcedEnabledByCustomFlag:
    IsWorfklowInstanceStepActionForcedEnabledByCustomFlag,
  IsApplicationCustomFlorinaFlag: IsApplicationCustomFlorinaFlag,
  IsWorkflowInstanceComponentVisibleByCustomFlag:
    IsWorkflowInstanceComponentVisibleByCustomFlag,
  IsApplicationAndSystemCustomFlorinaFlag:
    IsApplicationAndSystemCustomFlorinaFlag,
  IsEnforcedReadOnlyAction: IsEnforcedReadOnlyAction,
};

/**
 * ENABLES OR DISABLES CODE CHECK FOR CUSTOM FLORINA FLAG
 */
const IS_CHECK_CODE_FOR_CUSTOM_FLORINA_FLAG =
  GlobalParametersHelper.getIsCustomFlorinaEnabled ?? false; //change if application is checking the following flag (only consumer)
const IS_CUSTOM_FLORINA_ENABLED =
  GlobalParametersHelper.getIsCustomFlorinaEnabled
    ? GlobalParametersHelper.getIsCustomFlorinaEnabled()
    : false;

/**
 * FLORINA SPECIFIC ACTION IDS
 */
const Florina = {
  FLORINA_STEP_ID: "bfaf78ba-c0ff-4454-8502-2eb5c79b2cdb",
  FlorinaSpecificActionTypeFilesEnum: {
    HOME_APPLICATION: "5f0ed1b6-e556-4c49-8454-b4bb18d3d2b2", //' Αίτηση συμμετοχής πολυκατοικίας/μονοκατοικίας/διαμερίσματος'
    TAX_E1: "be1e9f60-3cc0-4923-b9b6-a6d5069dc52a", //'Φορολογική δήλωση εισοδήματος (Έντυπο Ε1)'
    HOME_USE_DECLERATION: "1ce0088e-d050-46af-bc6b-a1d998857d62", //'Υπεύθυνη Δήλωση για τη χρήση της κατοικίας'
    HOME_USER_DECLERATION: "dc179a71-153c-4ad2-a2da-64cc78c3b3c9", //'Υπεύθυνη Δήλωση του προσώπου που χρησιμοποιεί την κατοικία'
    TAX_E9: "3c056795-8a6d-4033-b83b-b2df2d078646", //'Φορολογική δήλωση ακινήτων (Έντυπο Ε9)'
    POWER_UTILITY_BILL: "253c7514-cc81-47fb-a8ad-54e429741929", //'Αντίγραφο πρόσφατου εκκαθαριστικού λογαριασμού ηλεκτρικού ρεύματος της κατοικίας
    TERMS_ACCEPTANCE_DECLERATION: "ea634966-306c-45c2-9459-864fc0dc69c6", //'Υπεύθυνη Δήλωση για την αποδοχή των όρων συμμετοχής στη δράση
    BUILDING_PERMIT: "c4782257-ed7e-413a-a2a2-1f61ca12caee", //'Αντίγραφο οικοδομικής Άδειας'
    HEAT_INSPECTION: "2182de3f-9022-475e-8594-3743d9a0c626", //'Έκθεση Επιθεώρησης του υφιστάμενου συστήματος θέρμανσης κτιρίου'
    INSPECTOR_REVIEW: "3862df38-c354-4b3c-b494-b63356b20ec3", //'Τεχνική Έκθεση ενεργειακού επιθεωρητή (μόνο για αιτήσεις διαμερισμάτων
    FUNDING_DOCUMENT_COSTS: "adf76dbd-a183-4360-9e3a-245a91a12d4f", //'Έντυπο Οικονομικής Προσφοράς για το κόστος της εσωτερικής εγκατάστ
    PRODUCT_REPORT: "06373936-ca07-4849-a027-a1efd8601483", //'Δελτίο προϊόντος'
    COMPLETION_DOCUMENT: "f38f7ffe-7131-4cd5-b9b9-97c101ba7d6f", //'Έντυπο καταγραφής εσωτερικής εγκατάστασης – Ολοκλήρωσης Έργου'
    TRANSACTION_DOCUMENT: "42c8aff2-7d1a-4090-ab3f-e9ccfe6e75c2", //'Φωτοαντίγραφα των παραστατικών λιανικών συναλλαγών'
    BANK_TRANSFER_DOCUMENT: "3898f223-4f00-4e14-929c-deb3ad0b1476", //'Φωτοαντίγραφο παραστατικών τραπεζικής συναλλαγής'
    DECLERATION_TO_RECEIVE: "e37f6d60-e30b-4d15-b5c8-64eaec1d943c", //'Υπεύθυνες Δηλώσεις – Εξουσιοδοτήσεις για την καταβολή της επιχορήγησης προς τους ανάδοχους
  },
  getFlorinaSpecificActionTypeFilesValues: () => {
    let result = Object.values(Florina.FlorinaSpecificActionTypeFilesEnum);
    return result;
  },
  getFlorinaSpecificActionTypesValues: () => {
    let result = Object.values(
      Florina.getFlorinaSpecificActionTypeFilesValues()
    );
    result = result.concat([
      "bd63fcac-8bac-46c7-a823-06f5f5dd7559", //Αίτηση Χρηματοδότησης
      "563e0d2b-5b26-47e9-9e84-1b1ad6bdad04", //Πίνακας Οικονομικής Προσφοράς
      "fd7c4589-ecb8-4bba-86bc-0e5bb70acd58", //Πίνακας Καταβληθέντος Ποσού Επιχορήγησης
    ]);
    return result;
  },
};

function IsWorfklowInstanceStepActionEnabledByCustomFlag(
  actionId,
  instanceIsCustomFlorinaFlag
) {
  instanceIsCustomFlorinaFlag = instanceIsCustomFlorinaFlag || false;
  function _isCustomActionEnabledRuleForFlorina(actionId) {
    let CUSTOM_ACTIONS_FLORINA = Florina.getFlorinaSpecificActionTypesValues();

    let isActionInCustomList = CUSTOM_ACTIONS_FLORINA.indexOf(actionId) > -1;
    if (
      IS_CHECK_CODE_FOR_CUSTOM_FLORINA_FLAG &&
      IS_CUSTOM_FLORINA_ENABLED == false &&
      isActionInCustomList
    ) {
      return false;
    }

    if (instanceIsCustomFlorinaFlag == false && isActionInCustomList) {
      return false;
    }

    return true;
  }

  if (_isCustomActionEnabledRuleForFlorina(actionId) == false) {
    return false;
  }
  //Add more rules here
  return true;
}

function IsWorfklowInstanceStepEnabledByCustomFlag(
  stepId,
  instanceIsCustomFlorinaFlag
) {
  instanceIsCustomFlorinaFlag = instanceIsCustomFlorinaFlag || false;

  function _isCustomStepEnabledRuleForFlorina(stepId) {
    let isStepInCustomList = Florina.FLORINA_STEP_ID.indexOf(stepId) > -1;

    if (
      IS_CHECK_CODE_FOR_CUSTOM_FLORINA_FLAG &&
      IS_CUSTOM_FLORINA_ENABLED == false &&
      isStepInCustomList
    ) {
      return false;
    }

    if (instanceIsCustomFlorinaFlag == false && isStepInCustomList) {
      return false;
    }

    return true;
  }

  if (_isCustomStepEnabledRuleForFlorina(stepId) == false) {
    return false;
  }
  //Add more rules here
  return true;
}

function IsWorfklowInstanceStepActionForcedEnabledByCustomFlag(
  actionId,
  instanceIsCustomFlorinaFlag,
  stepStatus
) {
  instanceIsCustomFlorinaFlag = instanceIsCustomFlorinaFlag || false;
  function _isCustomActionEnabledRuleForFlorina(actionId) {
    let CUSTOM_ACTIONS_FLORINA =
      Florina.getFlorinaSpecificActionTypeFilesValues();
    let isActionInCustomList = CUSTOM_ACTIONS_FLORINA.indexOf(actionId) > -1;
    let isWithinCustomStepStatus = [3, 4, 5, 6, 7, 8].indexOf(stepStatus) > -1;
    if (
      instanceIsCustomFlorinaFlag == true &&
      isActionInCustomList &&
      isWithinCustomStepStatus
    ) {
      return true;
    }

    return false;
  }

  if (_isCustomActionEnabledRuleForFlorina(actionId) == true) {
    return true;
  }
  //Add more rules here
  return false;
}

function IsApplicationCustomFlorinaFlag(application) {
  let result = (application?.city ?? "") == "Φλώρινα";
  return result;
}

/**
 * It is used in Consumer App, to hide or show specific ui elements
 * @param {*} application , optional
 * @returns {boolean} boolean
 */
function IsWorkflowInstanceComponentVisibleByCustomFlag(application) {
  function _isCustomStepVisibleRuleForFlorina(application) {
    return IS_CUSTOM_FLORINA_ENABLED;
  }

  if (_isCustomStepVisibleRuleForFlorina(application) == false) {
    return false;
  }
  //Add more rules here
  return true;
}

/**
 * It is used in Consumer App
 * @param {*} application , optional
 * @returns {boolean} result
 */
function IsApplicationAndSystemCustomFlorinaFlag(application) {
  let result =
    IS_CUSTOM_FLORINA_ENABLED && IsApplicationCustomFlorinaFlag(application);
  return result;
}

function IsEnforcedReadOnlyAction(actionId, initialStepsDatas) {
  let enforcedReadonlyActions = [
    "c8e506fa-2ed7-438f-97fe-a889e04addd2", //"Αριθμός"
    "6f3c7495-1563-4cfd-8a19-6ab37ebcade5", //	"Αριθμός"
    "11bb752a-fe5f-4eb6-b7c2-3263e179c874", //	"Διεύθυνση Παροχής"
    "6d0e6c57-be24-4b0a-9dc7-e2469dacf616", //	"Διεύθυνση Παροχής*"
    "89f039ce-e360-4511-83e8-21747979e601", //	"Επίθετο*"
    "fe1612b5-a45d-4227-9588-77f95c41d709", //	"Ηλεκτρονική Διεύθυνση*"
    "586a3e33-7344-4c88-b85a-f4c489e9b416", //	"Ιδιότητα Αιτούντος*"
    "7cc1f38d-20c9-4789-9b19-7bad52eed215", //	"Κατηγορία Πελάτη*"
    "890e3052-6a11-4b7e-b181-d9c83d037269", //	"Κατηγορία Πελάτη*"
    "76647334-f9cd-473a-9ac0-6d4cecc4506d", //	"Όνομα*"
    "3a5b4352-fb9a-4b37-9925-633f607abc3b", //	"Πόλη"
    "690f1574-66a2-495f-b13e-10a7e10e060c", //	"Πόλη*"
    "eac5574d-dfdd-4bc3-b924-921b49393ef9", //	"Προβλεπόμενη χρήση*"
    "dcd57208-e107-4b07-9cb3-8b4fde107dea", //	"Ταχυδρομικός Κώδικας"
    "37073bac-93d5-4c00-a55c-e18268634229", //	"Ταχυδρομικός Κώδικας*"
    "b90e97b5-96a9-4c62-84d2-05c72fc991dd", //	"Τηλέφωνο 1*"
    "43455ba1-6810-404f-b314-1b6c222b4b0b",
  ];
  if (enforcedReadonlyActions.indexOf(actionId) > -1) {
    let initialData =
      initialStepsDatas.find((x) => x.id == actionId)?.data ?? "";
    let isNullOrEmptyString = false;
    if (!initialData || initialData.toString().trim() === "") {
      isNullOrEmptyString = true;
    }
    if (isNullOrEmptyString) {
      return false;
    }
    return true;
  }
  return null;
}
export default WorkflowInstancesHelper;
